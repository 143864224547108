
import { defineComponent, onMounted, onBeforeUnmount, ref } from 'vue';
import { saveReleaseBatchCover, getReleaseBatchCovereContent } from '@/API/program'
import { message } from "ant-design-vue"
export default defineComponent({
    name: 'Cover Page Edit',
    setup() {
        // 切换当前中英文
        const activeWording = ref<number>(1)

        // 渲染用的数据
        const setHTML = ref({
            batchId: '',
            coverCn: '',
            coverEn: ''
        })

        let instance: any;

        // 渲染到编辑器
        const getHTML = () => {
            instance.txt.html(`${activeWording.value === 1 ? String(setHTML.value.coverEn).replaceAll('<script', '&lt;script') : String(setHTML.value.coverCn).replaceAll('<script', '&lt;script')}`)
        }

        const init = () => {
            if (instance) {
                instance.destroy()
                instance = null
            }
            
            const E = window.wangEditor
            instance = new window.wangEditor('#toolbar-container', '#text-container')
            const params = {
                batchId: '',
            }
            if (sessionStorage.getItem('batchIdParams') && sessionStorage.getItem('batchIdParams') !== null) {
                Object.assign(params, JSON.parse(sessionStorage.getItem('batchIdParams') as string))
            }
            getReleaseBatchCovereContent({params: params}).then(res => {
                setHTML.value = res
                getHTML()
            })
            // 隐藏表情，和视频菜单
            const { BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip } = E
            // 创建 class
            class CustomColorMenu extends BtnMenu {
                constructor(editor: any) {
                    const $elem = E.$(
                        `<div id="color-button" class="w-e-menu" style='width:80px'>
                            <button style='width:80px'>替换颜色</button>
                        </div>`
                        
                    )
                    super($elem, editor)
                }
                // 菜单点击事件
                clickHandler() {
                    const editor = this.editor
                    const isEmptySelection = editor.selection.isSelectionEmpty()
                    const $selectionElem = editor.selection.getSelectionContainerElem()?.elems[0]

                    if ($selectionElem == null) return

                    // 获取选区范围的文字
                    const $selectionText = editor.selection.getSelectionText()
                    // // 如果设置的是 a 标签就特殊处理一下，避免回车换行设置颜色无效的情况
                    // // 只处理选中a标签内全部文字的情况，因为选中部分文字不存在换行颜色失效的情况
                    // if ($selectionElem.nodeName === 'A' && $selectionElem.textContent === $selectionText) {
                    //     // 创建一个相当于占位的元素
                    //     const _payloadElem = $('<span>&#8203;</span>').getNode()
                    //     // 添加到a标签之后
                    //     $selectionElem.appendChild(_payloadElem)
                    // }
                    if (document && (document?.getElementById('color') as HTMLInputElement).value) {
                        editor.cmd.do('foreColor', (document?.getElementById('color') as HTMLInputElement).value)
                    }
                    

                    if (isEmptySelection) {
                        // 需要将选区范围折叠起来
                        editor.selection.collapseRange()
                        editor.selection.restoreSelection()
                    }
                }
                // 菜单激活状态
                tryChangeActive() {
                    this.active() // 菜单激活
                    // this.unActive() // 菜单不激活
                }
            }
            // 注册菜单 [全局模式注册菜单]
            E.registerMenu('CustomColorMenu', CustomColorMenu)


            instance.config.excludeMenus = [
                'emoticon',
                'video'
            ]
            // 可使用 base64 格式保存图片。即，可选择本地图片，编辑器用 base64 格式显示图片。
            // 上传图服务器
            // instance.config.uploadImgServer = '/upload-img'
            // uploadImgShowBase64（base64 格式）和 uploadImgServer（上传图片到服务器）两者不能同时使用
            instance.config.uploadImgShowBase64 = true
            // 隐藏网络图片
            instance.config.showLinkImg = false
            instance.config.Hr = false
            instance.create()
            const colorSelector = document.createElement('INPUT')
            colorSelector.setAttribute('id', 'color')
            colorSelector.setAttribute('placeholder', '请输入颜色英文')
        }
        
        
        // 保存编辑后的wording
        const handleSaveWording = () => {
            const params: {
                batchId: string;
                coverEn?: string;
                coverCn?: string;
            } = {
                batchId: ''
            }
            params.batchId = JSON.parse(sessionStorage.getItem('batchIdParams') as string).batchId
            activeWording.value === 1 ? params.coverEn = instance.txt.html() : params.coverCn = instance.txt.html()
            saveReleaseBatchCover(params).then(() => {
                message.success('Save successed!')
                init()
            })
        }

        // 切换中英文
        const handleWording = (acitve: number) => {
            activeWording.value = acitve
            getHTML()
        }

        const isFixed = ref(false)
        onMounted(() => {
            init()
            window.addEventListener('scroll', () => {
                isFixed.value = document.documentElement.scrollTop > 200
            })
        })

        // 在路由卸载之后，需要清掉编辑器
        onBeforeUnmount(() => {
            instance.destroy()
            instance = null
        })

        return {
            isFixed,
            handleSaveWording,
            activeWording,
            handleWording
        }
    }
})
